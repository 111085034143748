<template>
  <UDropdown
    :items="items"
    :ui="{ item: { disabled: 'opacity-100' } }"
    :popper="{ placement: 'bottom-start' }"
  >
    <div
      class="flex flex-col items-center group/trigger text-mth hover:text-white data-[state=open]:text-white landscape:text-gray-700 landscape:hover:text-lmt
      data-[state=open]:landscape:text-mbg"
      aria-label="User options"
    >
      <span class="i-heroicons-user-circle group-data-[state=open]/trigger:i-heroicons-user-circle-solid !w-6 !h-6" />
      <div class="hidden landscape:xl:block text-sm font-light whitespace-nowrap">
        {{ name }}
      </div>
    </div>
  </UDropdown>
</template>

<script setup lang="ts">
import { useUser } from '~/stores/user'
import type { Database } from '~/supabase/types';
const { name, email } = useUser()
const items = [
  [{
    label: name,
    disabled: true,
    labelClass: 'text-sm text-gray-900'
  }, {
    label: email,
    disabled: true,
    labelClass: 'text-sm font-medium text-gray-900'
  }], [{
    label: 'Account',
    icon: 'i-mingcute-settings-5-line',
  }, {
    label: 'Log Out',
    icon: 'i-mingcute-exit-line',
    click: logout
  }]
]
async function logout() {
  const { error } = await useSupabaseClient<Database>().auth.signOut()
  if (error) {
    localStorage.removeItem('shairz-auth-token')
    reloadNuxtApp({ path: '/', persistState: false })
  }
}
</script>
