<template>
  <NuxtPwaAssets />
  <div
    :data-menu="sideBarOpened"
    class="w-screen h-dvh max-h-dvh grid grid-cols-[min-content_1fr_min-content_min-content] grid-rows-[min-content_4rem_1fr] landscape:grid-cols-[4rem_min-content_1fr_min-content_min-content] landscape:data-[menu=true]:grid-cols-[12rem_min-content_1fr_min-content_min-content] landscape:xl:grid-cols-[16rem_min-content_1fr_min-content_min-content] landscape:xl:data-[menu=true]:grid-cols-[16rem_min-content_1fr_min-content_min-content] transition-[grid-template-columns] group/layout"
  >
    <LayoutOffline class="row-start-1 col-start-1 col-span-5" />
    <!-- Icon or Logo -->
    <div
      class="flex grow row-start-2 col-start-1 bg-mbg border-b landscape:border-min items-center p-4 text-white"
      :title="version"
    >
      <div
        v-if="domain === 'shairz'"
        class="font-['Varela_Round'] font-bold text-2xl"
      >
        <p class="flex items-center justify-center h-8 w-8 rounded-full border border-white landscape:group-data-[menu=true]/layout:hidden landscape:xl:hidden">
          s
        </p>
        <p class="hidden landscape:group-data-[menu=true]/layout:block landscape:xl:block landscape:text-3xl landscape:xl:text-4xl">
          shairz
        </p>
      </div>
      <div
        v-else
        class="w-max"
      >
        <img
          src="~/assets/oloryn/images/Glyph-White.svg"
          class="h-8 !w-8 landscape:group-data-[menu=true]/layout:hidden landscape:xl:hidden"
        >
        <img
          src="~/assets/oloryn/images/logo.svg"
          class="h-8 hidden landscape:group-data-[menu=true]/layout:block landscape:xl:block"
        >
      </div>
    </div>
    <!-- Hamburger switch -->
    <div class="hidden landscape:flex row-start-2 landscape:col-start-2 border-b items-center p-4 hover:text-lmt">
      <span
        v-if="$store.organization"
        class="landscape:xl:hidden !w-6 !h-6 i-heroicons-bars-3 group-data-[menu=true]/layout:i-heroicons-x-mark group-data-[menu=true]/layout:landscape:xl:hidden cursor-pointer"
        @click="sideBarOpened = !sideBarOpened"
      />
    </div>
    <!-- Central bar title -->
    <div class="hidden landscape:flex landscape:row-start-2 landscape:col-start-3 border-b items-center justify-center text-2xl font-light text-gray-400">
      {{ sectionTitle }}
    </div>
    <!-- Organization dropdown -->
    <nav class="flex row-start-2 col-start-3 landscape:col-start-4 items-center justify-center p-4 pr-2 border-b bg-mbg text-gray-200 hover:text-white landscape:bg-white landscape:text-gray-700 landscape:hover:text-lmt">
      <Organization />
    </nav>
    <!-- User Menu -->
    <nav class="flex row-start-2 col-start-4 landscape:col-start-5 items-center justify-center p-4 pl-2 border-b bg-mbg landscape:bg-white">
      <UserMenu />
    </nav>
    <!-- Main section -->
    <main class="flex row-start-3 col-start-1 col-span-4 landscape:col-start-2 flex-col overflow-y-auto scrollbar-hide bg-gray-50">
      <slot />
    </main>
    <!-- Navigation Menu -->
    <aside class="flex row-start-2 col-start-2 border-b landscape:border-none landscape:row-start-3 landscape:col-start-1 bg-mbg flex-col overflow-y-auto scrollbar-hide justify-center landscape:justify-start">
      <Navigation
        v-if="$store.organization"
        @click="sideBarOpened = false"
      />
    </aside>
    <!-- Toast Notifications -->
    <UNotifications />
  </div>
</template>

<script setup lang="ts">
const { public: { version, domain } } = useRuntimeConfig()

const sideBarOpened = ref(false)

const sectionTitle = computed(() => {
  const route = useRoute()
  if (route.name === 'organizations') {
    return 'Please select a company below:'
  } else if (route.name === 'tables') {
    return 'Cap Tables'
  } else {
    const name = route.name as string ?? ''
    return name.charAt(0).toUpperCase() + name.slice(1)
  }
})
</script>
