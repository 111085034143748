<template>
  <div
    v-if="$store.organization"
    class="flex flex-col items-center cursor-pointer"
    @click="$router.push('/organizations')"
  >
    <div class="w-6 h-6">
      <img
        v-if="$store.organization.logoUrl"
        :src="$store.organization.logoUrl"
        class="w-6 h-6 rounded-full object-cover grayscale border border-gray-200"
        alt="Organization Logo"
      >
      <span
        v-else
        class="i-heroicons-building-office hover:i-heroicons-building-office-solid !w-6 !h-6"
      />
    </div>
    <div class="hidden landscape:xl:block text-sm font-light whitespace-nowrap">
      {{ $store.organization.name }}
    </div>
  </div>
</template>
